var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { id: "navbar" } },
    [
      _c(
        "b-navbar-brand",
        {
          staticClass: "navbar-brand",
          on: {
            click: function($event) {
              return _vm.showMenu()
            }
          }
        },
        [_c("img", { attrs: { src: require("@/assets/img/menu-btn.png") } })]
      ),
      _c(
        "div",
        {
          staticClass: "navbar-collapse collapse w-100 order-3 dual-collapse2"
        },
        [
          _c("ul", { staticClass: "navbar-nav ml-auto" }, [
            _c("li", { staticClass: "nav-item" }, [
              _c("span", { staticClass: "nav-title text-uppercase" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
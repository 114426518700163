<template>
  <b-navbar id="navbar">
    <b-navbar-brand class="navbar-brand" @click="showMenu()">
      <img src="@/assets/img/menu-btn.png" />
    </b-navbar-brand>
    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <span class="nav-title text-uppercase">{{ title }}</span>
        </li>
      </ul>
    </div>
  </b-navbar>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'header-navigation',
  props: {
    title: String
  },
  methods: {
    ...mapMutations(['showMenu'])
  },
  computed: {}
}
</script>
<style lang="scss">
@import '@/styles.scss';

#navbar {
  background-color: #f8f8f8;
  padding-left: 20px;
  padding-right: 20px;

  .navbar-brand img {
    width: 20px;
    cursor: pointer;
  }

  .nav-title {
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: 0;
  }
}
</style>
